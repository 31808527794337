import type { Crystallize } from "~/crystallize/spec";
import type { Episode, Podcast, PodcastCard } from "~/types/podcasts";
import type { EdgeNode, ItemRelation } from "~/utils/crystallize";

export function toPodcastCard(item: ItemRelation | EdgeNode): PodcastCard {
  const card: PodcastCard = {
    id: item.id,
    title: "",
    slug: pathToSlug(item.path ?? ""),
  };

  if (item.components) {
    for (const component of item.components) {
      if (!component.content) {
        continue;
      }

      const id = component.id as Crystallize.ComponentId<"podkast">;

      switch (id) {
        case "tittel":
          card.title = getSingleLine(component);
          card.slug.custom = slugify(card.title);
          break;
        case "link":
          card.link = getSingleLine(component);
          break;
        case "cover":
          card.cover = getSingleImage(component, 500);
          break;
      }
    }
  }

  return card;
}

export function toPodcast(item: ItemRelation): Podcast {
  const podcast = createDocument<Podcast>(item, {
    episodes: [],
    contentType: "podcast",
    colorTheme: "green-seagreen",
    variant: "feature",
  });

  if (item.components) {
    for (const component of item.components) {
      if (!component.content) {
        continue;
      }

      const id = component.id as Crystallize.ComponentId<"podkast">;

      switch (id) {
        case "tittel":
          podcast.title = getSingleLine(component);
          podcast.slug.custom = slugify(podcast.title);
          break;
        case "thumbnailbilde":
          podcast.thumbnailImage = getSingleImage(component, 500);
          break;
        case "coverbilde":
          podcast.cover = getSingleImage(component, 1366);
          break;
        case "cover":
          podcast.podcastCover = getSingleImage(component, 768);
          break;
        case "fargetema": {
          const theme = getSelections(component, "value")[0];
          if (theme) {
            podcast.colorTheme = theme;
          }
          break;
        }
        case "ingress":
          podcast.intro = getRichText(component);
          break;
        case "beskrivelse":
          podcast.description = getRichText(component);
          break;

        case "kategorisering": {
          for (const chunk of getSingleChunks(component)) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<
              "podkast",
              "kategorisering"
            >;

            switch (id) {
              case "trinn":
                podcast.levels = getLevels(chunk);
                break;

              case "tema":
                podcast.theme.push(...getSelections(chunk, "value"));
                break;

              case "fag":
                podcast.subject = getSelections(chunk, "value");
                break;
            }
          }
          break;
        }

        case "seo": {
          for (const chunk of getSingleChunks(component)) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<"podkast", "seo">;

            switch (id) {
              case "sidetittel":
                podcast.seo.title = getSingleLine(chunk);
                break;
              case "beskrivelse":
                podcast.seo.description = getSingleLine(chunk);
                break;
            }
          }
          break;
        }

        case "some-metadata": {
          for (const chunk of getSingleChunks(component)) {
            if (!chunk.content) {
              continue;
            }

            const id = chunk.id as Crystallize.ChunkId<
              "podkast",
              "some-metadata"
            >;

            switch (id) {
              case "tittel":
                podcast.seo.ogTitle = getSingleLine(chunk);
                break;
              case "beskrivelse":
                podcast.seo.ogDescription = getSingleLine(chunk);
                break;
              case "twitter-card-tittel":
                podcast.seo.twitterTitle = getSingleLine(chunk);
                break;
              case "twitter-card-beskrivelse":
                podcast.seo.twitterDescription = getSingleLine(chunk);
                break;
            }
          }
          break;
        }

        case "episoder": {
          podcast.episodes = getItemRelations(component).map((item) => {
            const episode: Episode = {
              id: item.id,
              date: new Date(),
            };

            if (item.components) {
              for (const c of item.components) {
                if (!c.content) {
                  continue;
                }

                const id = c.id as Crystallize.ComponentId<
                  Crystallize.ItemRelationId<"podkast", "episoder">
                >;

                switch (id) {
                  case "tittel":
                    episode.title = getSingleLine(c);
                    break;
                  case "published":
                    episode.date = getDatetime(c);
                    break;
                  case "beskrivelse":
                    episode.description = getRichText(c);
                    break;
                  case "embed":
                    episode.embed = getSingleLine(c);
                    break;
                  case "transkribering":
                    episode.transcription = getRichText(c);
                    break;
                  case "spotify-link":
                    episode.spotifyLink = getSingleLine(c);
                    break;
                }
              }
            }

            return episode;
          });
          break;
        }
      }
    }
  }

  podcast.episodes?.sort((a, b) => {
    return new Date(b.date).getTime() - new Date(a.date).getTime();
  });

  podcast.theme.push("podcast");

  return podcast;
}
